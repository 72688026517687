import React from "react"
import Layout from "../components/Layout"
import Raj from "../images/Raj.png"
import Prasad from "../images/Prasad.png"
import Subash from "../images/Subash.png"
import Mohan from "../images/Mohan.png"
import Aman from "../images/Aman.png"
import Vignesh from "../images/Vignesh.png"
import Thiyagarajan from "../images/Thiyagarajan.png"
import "animate.css"

const Team = () => {
  return (
    <div>
      <Layout>
        <section class="text-gray-600 w-full body-font font-quick ">
          <div class="container w-full px-20 mx-auto">
            <div class="flex flex-wrap justify-center">
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInLeft">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 rounded-lg w-full h-[50%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Raj}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Raj
                    </h2>
                    <h3 class="text-white mb-1">Founder & CEO</h3>
                  </div>
                </div>
              </div>
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInDown animate__delay-1s">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 w-full h-[60%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Prasad}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Prasad
                    </h2>
                    <h3 class="text-white mb-1">Director</h3>
                    <h3 class="text-white mb-1">(Finance & Marketing)</h3>
                  </div>
                </div>
              </div>
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInRight">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 w-full h-[50%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Thiyagarajan}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Thiyagarajan
                    </h2>
                    <h3 class="text-white mb-1">Creative Head</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-center">
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInUp animate__delay-2s">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 rounded-lg w-full h-[60%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Vignesh}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Vignesh
                    </h2>
                    <h3 class="text-white mb-1">Digital Marketing Analyst</h3>
                  </div>
                </div>
              </div>
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInUp animate__delay-3s">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 w-full h-[60%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Subash}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Subash
                    </h2>
                    <h3 class="text-white mb-1">Full Stack Developer</h3>
                  </div>
                </div>
              </div>
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInUp animate__delay-4s">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 rounded-lg w-full h-[60%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Mohan}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Mohan
                    </h2>
                    <h3 class="text-white mb-1">Backend Developer</h3>
                  </div>
                </div>
              </div>
              <div class="p-4 lg:w-1/4 md:w-1/2 animate__animated animate__fadeInUp animate__delay-5s">
                <div class="h-full flex flex-col items-center text-center">
                  <img
                    alt="team"
                    class="flex-shrink-0 rounded-lg w-full h-[60%] object-contain object-center mb-2 hover:animate-wiggle"
                    src={Aman}
                  />
                  <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-[#FFA314]">
                      Aman
                    </h2>
                    <h3 class="text-white mb-1">Game Developer</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Team
